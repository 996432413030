<template>
  <div>
    <form  @submit.prevent="submit">
      <div class="row justify-content-center">
        <div class="offset-1 col-9 p-2" style="font-size:12px;">
          <div class="shadow p-3 bg-white rounded-lg">
          <!-- INPUT -->
          <div class="sm-form">
            <input
              type="text"
              id="id"
              name="id"
              class="form-control form-control-sm"
              placeholder="id"
              v-model="vdata.id"
              disabled
            />
          </div>
         <div class="sm-form ">
             <label for="level">Your level</label>
             <input type="text" id="level" name="level" class="form-control form-control-sm" placeholder="level" v-model="vdata.level" >
         </div>
          <!-- END INPUT -->
          <!-- BUTTON PART -->
          <div class="mt-3 p-3">
            <button
              type="submit"
              class="btn btn-sm"
              :class="
                btn == 'tambah'
                  ? 'btn-primary'
                  : btn == 'update'
                  ? 'btn-success'
                  : 'btn-danger'
              "
            >
              <span class="typcn typcn-info"></span> Proses  {{ btn }}
            </button>
            <div class="float-right">
              <button
                type="button"
                v-show="btn!='tambah'"
                @click="
                  btn = 'tambah';
                  vdata = {};
                "
                class="btn btn-sm btn-dark"
              >
                <span class="typcn typcn-edit"></span> Tambah
              </button>
              <button
                type="button"
                @click="btn = 'delete'"
                v-show="btn!='tambah'"
                class="ml-3 btn btn-sm btn-danger"
              >
                <span class="typcn typcn-delete"></span> Delete
              </button>
            </div>
          </div>
          </div>
          <!-- END BUTTON PART -->
        </div>
        <div class="offset-1 col-9 p-2" style="font-size:12px;">
          <div class="shadow rounded-lg">
            <!-- TABLE PART -->
            <div v-if="datanya.length>0" class="p-2">
             <div class="text-right">
                <vue-json-excel :data="datanya">
                  <button type="button" class="btn btn-sm btn-dark">
                    Export <span class="typcn typcn-chart-area"></span>
                  </button>
                </vue-json-excel>
              </div>
                <Btables :tables="datanya"  :cari="['']" :hide="['']" @selected="ambil" ref="btable" />
            </div>
            <!-- END TABLE PART -->
         </div>
        </div>
      </div>
    </form>
  </div>
</template>

<script>
// TOTURIAL 
// ganti source autophp, ganti source component btable, ganti path table sdb
import autophp from "@/plugins/autophp_kasir.js"; // ganti source nya
import Btables from "@/components/Btables.vue"; //component btable nya
let sdb = new autophp();
export default {
  components: {
    Btables,
  },
  layout:'app_shop_admin',
  data() {
    return {
      selected: false,
      vdata: {},
      btn: "tambah",
      datanya:[],
    };
  },
  methods: {
    app_kasir_level(){
      this.$refs.btable.getData()
    },
    getData(){
      sdb.collection("app_kasir_level",false).doc().select(`select * from app_kasir_level`).then(res => {
        this.datanya=res;
        this.$forceUpdate();
      });
      
    },
    ambil(data) {
      this.vdata = data;
      this.btn = "update";
      this.$forceUpdate();
    },
    async submit() {
      let that=this
      if (confirm("Apakah yakin proses dilanjutkan ?")) {
        if (this.btn == "tambah") {
          //idnya letak di vdata
          delete this.vdata.id;
          await sdb
            .collection("app_kasir_level")
            .doc()
            .set(this.vdata)
              that.getData();
            // that.nuxt.emit('getData')
        } else if (this.btn == "update") {
         await sdb
            .collection("app_kasir_level")
            .doc()
            .set(this.vdata)
              that.getData();
            // that.nuxt.emit('getData')
        } else if (this.btn == "delete") {
          await sdb
            .collection("app_kasir_level")
            .doc(this.vdata.id)
            .delete()
              that.getData();
            // that.nuxt.emit('getData')
        }
        // this.nuxt.emit('getData','ok')
      }
    },
  },
  async mounted() {
    this.getData();
  },
};
</script>